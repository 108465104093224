
import PaneTitle from '@/components/panes/PaneTitle.vue'
import { currentStore } from '@/store'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ParadigmIntro',
  props: {
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState(currentStore, ['paradigmContent']),
  },
  components: { PaneTitle },
})
